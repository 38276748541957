<template>
    <div style="padding:0 20px;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="110px" class="login-form">
            <!-- <el-form-item prop="timeValue" label="日期：">
                <div>{{userItem.name}}</div>
            </el-form-item> -->
            <el-form-item prop="desc" label="跟进人员：">
                <div>{{userItem.bd_name}}</div>
            </el-form-item>
            <el-form-item prop="tag1" label="行为标签：">
                <div>
                    <el-checkbox-group v-model="ruleForm.tag1">
                        <el-checkbox v-for="(item,idx) in tagList1" :key="idx" :label="item.id">
                            {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form-item>
            <el-form-item prop="tag2" label="联系标签：">
                <div>
                    <el-checkbox-group v-model="ruleForm.tag2">
                        <el-checkbox v-for="(item,idx) in tagList2" :key="idx" :label="item.id">
                            {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form-item>
            <el-form-item prop="log_content" label="跟进日志：">
                <el-input type="textarea" :rows="3" placeholder="请输入跟进日志" v-model="ruleForm.log_content"></el-input>
            </el-form-item>
            <el-form-item prop="tag3" label="产品问题标签：">
                <div>
                    <el-checkbox-group v-model="ruleForm.tag3">
                        <el-checkbox v-for="(item,idx) in tagList3" :key="idx" :label="item.id">
                            {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form-item>
            <el-form-item prop="feedback" label="产品反馈：">
                <el-input type="textarea" :rows="3" placeholder="请输入产品反馈" v-model="ruleForm.feedback"></el-input>
            </el-form-item>
            <el-button type="primary" @click="submitForm()" style="width: 100%;">提交</el-button>
        </el-form>
    </div>
</template>
<script>
    import { listTag,addLog } from '@/service/crm';
    export default {
        props: ['userItem'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入权限分类名称', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    comp_id:null,
                    log_content:null,
                    feedback:null,
                    tag1:[],
                    tag2:[],
                    tag3:[],
                },
                tagList1:[],
                tagList2:[],
                tagList3:[],
            }
        },
        watch:{
            userItem:{
                handler() {
                    this.ruleForm.comp_id = this.userItem.id;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm.comp_id = this.userItem.id;
            // console.log(this.ruleForm);
            
        },
        mounted() {
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                listTag({tag_type:1,stat:1,}).then(rst => {
                    if (rst && rst.length>0) {
                        this.tagList1 = rst;
                    }
                    console.log(rst);
                });
                listTag({tag_type:2,stat:1,}).then(rst => {
                    if (rst && rst.length>0) {
                        this.tagList2 = rst;
                    }
                    console.log(rst);
                });
                listTag({tag_type:3,stat:1,}).then(rst => {
                    if (rst && rst.length>0) {
                        this.tagList3 = rst;
                    }
                    console.log(rst);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                addLog(this.ruleForm).then(rst => {
                    this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("新增日志成功");
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    .list>div {
        padding: 5px 0;
    }
    div>>> .el-form-item__label {
        font-weight:600;
    }
    
</style>