<template>
    <div style="height:100%">
        <div class="page-header">客户管理</div>
        <div class="block tab flex">
            <div class="tab_menu flex-1">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <div class="tab flex">
            <div class="flex-1">
                <el-select style="width:170px;margin-right:10px;"
                    v-model="queryOpt.fd" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                <el-input style="max-width:250px;" placeholder="搜索客户"
                prefix-icon="el-icon-search" v-model="searchValue"></el-input>
                <el-button @click="search()" style="width:80px;position:relative;left:-4px;margin-right:10px" type="primary">搜索</el-button>
                <el-checkbox @change="changeIsSelf()" v-model="is_self">只显示自己</el-checkbox>
            </div>
            <div style="padding:4px 0;">
                <el-button @click="goto('/client/addUser?type='+queryOpt.comp_type)" size="small" type="primary">新增客户</el-button>
                <el-button v-if="!smsFlag" @click="showCheck(1)" size="small" type="primary">短信推送</el-button>
                <el-button v-if="smsFlag" @click="showCheck(1)" size="small" type="primary" plain>取消</el-button>
                <el-button v-if="smsFlag" @click="gotoSms()" size="small" type="primary">确定</el-button>
                <el-button v-if="!mailFlag" @click="showCheck(2)" size="small" type="primary">邮件推送</el-button>
                <el-button v-if="mailFlag" @click="showCheck(2)" size="small" type="primary" plain>取消</el-button>
                <el-button v-if="mailFlag" @click="gotoMail()" size="small" type="primary">确定</el-button>
                <!-- <el-button @click="goto('/client/apply')" size="small" type="primary">运营资质申请</el-button> -->
            </div>
        </div>
        <div class="block" style="height: calc(100% - 235px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" :row-key="getRowKeys" ref="multipleTable"
                    @selection-change="handleSelectChange" :reserve-selection="true" 
                    style="width: 100%">
                    <el-table-column v-if="smsFlag || mailFlag" prop="id"
                        type="selection" :reserve-selection="true"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="id" label="ID">
                        <template slot-scope="scope">
                            <div class="flex flex-pack-center">
                                <!-- <div v-if="smsFlag || mailFlag" style="margin:0 10px;">
                                    <el-checkbox @change="selectSingle($event,scope.row)" :disabled="smsFlag && !scope.row.mobile || mailFlag && !scope.row.mail" v-model="scope.row.checked"></el-checkbox>
                                </div> -->
                                <div>{{scope.row.id}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="privs" label="客户名称" min-width="150">
                        <template slot-scope="scope">
                            <div>{{scope.row.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="当前跟进人员" min-width="105">
                        <template slot-scope="scope">
                            <div v-if="scope.row.bd_name">{{scope.row.bd_name}}</div>
                            <div v-else>无分配</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="电话" min-width="120">
                        <template slot-scope="scope">
                            <div>{{scope.row.mobile}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mail" label="邮箱" min-width="110">
                        <template slot-scope="scope">
                            <div>{{scope.row.mail}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="privs" label="标签" min-width="200">
                        <template slot-scope="scope">
                            <div class="flex">
                                <div style="margin-right:5px;">行为标签:</div>
                                <div v-if="scope.row.tag1 && scope.row.tag1.length>0">
                                    <span style="margin-right:5px;" v-for="(item1,idx1) in scope.row.tag1" :key="idx1">{{item1.name}}</span>
                                </div>
                                <div v-else>无</div>
                            </div>
                            <div class="flex">
                                <div style="margin-right:5px;">联系标签:</div>
                                <div v-if="scope.row.tag2 && scope.row.tag2.length>0">
                                    <span style="margin-right:5px;" v-for="(item1,idx1) in scope.row.tag2" :key="idx1">{{item1.name}}</span>
                                </div>
                                <div v-else>无</div>
                            </div>
                            <div class="flex">
                                <div style="margin-right:5px;">产品问题标签:</div>
                                <div v-if="scope.row.tag3 && scope.row.tag3.length>0">
                                    <span style="margin-right:5px;" v-for="(item1,idx1) in scope.row.tag3" :key="idx1">{{item1.name}}</span>
                                </div>
                                <div v-else>无</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.comp_stat == 0">未注册</div>
                            <div v-if="scope.row.comp_stat == 1">已注册</div>
                            <div v-if="scope.row.comp_stat == 2">已认证</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="营销推送日志" min-width="105">
                        <template slot-scope="scope">
                            <div v-if="scope.row.last_promote_at!=0">
                                <div class="flex">
                                    <div style="margin-right:10px">标题</div>
                                    <div class="ellipsis">{{scope.row.last_promote_title}}</div>
                                </div>
                                <div class="flex">
                                    <div style="margin-right:10px">方式</div>
                                    <div>
                                        <div v-if="scope.row.last_promote_type==1">短信</div>
                                        <div v-if="scope.row.last_promote_type==2">邮件</div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div style="margin-right:10px">时间</div>
                                    <div>{{scope.row.last_promote_at | moment("YYYY/MM/DD")}}</div>
                                </div>
                            </div>
                            <div v-if="scope.row.last_promote_at==0">
                                暂无推广日志
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="活动时间" min-width="105">
                        <template slot-scope="scope">
                            <div>{{scope.row.created_at | moment("YYYY/MM/DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="运营日志" width="80">
                        <template slot-scope="scope">
                            <el-button type="text" v-if="scope.row.bd_id==user.id" size="small" @click="addJournal(scope.row)">
                                新增
                            </el-button>
                            <el-button type="text" size="small" @click="goto('/client/log?id='+scope.row.id)">
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button v-if="user.privids.indexOf(501)>=0" @click="FpPerson(scope.row)" type="text" size="small">
                                分配跟进人员
                            </el-button>
                            <el-button @click="goto('/client/addUser?type='+queryOpt.comp_type+'&id='+scope.row.id)" type="text" size="small">
                                编辑
                            </el-button>
                            <el-button v-if="user.privids.indexOf(502)>=0" @click="delUser(scope.row)" type="text" size="small">
                                删除
                            </el-button>
                            <!-- <el-button type="text" size="small" @click="edit(scope.row)">
                                运营日志
                            </el-button> -->
                            <!-- <el-button type="text" size="small" @click="goto('/client/qualSubmit')">
                                资质代传
                            </el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px" class="flex">
                <div class="flex-1">
                    <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize" :total="total"></el-pagination>
                </div>
                <div v-if="allSelectedId" style="line-height:32px;padding:0 20px;font-size:15px;">已选中
                    <span style="color:#409EFF;">{{allSelectedId.length}}</span>
                </div>
            </div>
        </div>
        <!-- <div style="height: calc(100% - 167px)">
            <ItemFactory v-if="list" :list="list" :comp_type="queryOpt.comp_type"></ItemFactory>
            <ItemSale v-if="tabIndex == 1"></ItemSale>
            <ItemDesign v-if="tabIndex == 2"></ItemDesign>
        </div> -->

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <AddUser v-if="popTitle=='新增用户'" :compType="queryOpt.comp_type" @refreshItems="refreshItems" @hide="popVisible=false"></AddUser>
            <AddPerson v-if="popTitle=='分配人员跟进'" :form="ruleForm" @refreshItems="refreshItems" @hide="popVisible=false"></AddPerson>
            <AddJournal v-if="popTitle=='新增日志'" :userItem="userItem" @refreshItems="refreshItems" @hide="popVisible=false"></AddJournal>
        </el-dialog>
        
    </div>
</template>
<script>
    import _ from 'lodash';
    import { listCompany,searchCompany,delCompany } from '@/service/crm';
    import AddUser from './AddUser.vue';
    import AddPerson from './AddPerson.vue';
    import AddJournal from './AddJournal.vue';
    // import ItemFactory from './ItemFactory.vue';
    // import ItemSale from './ItemSale.vue';
    // import ItemDesign from './ItemDesign.vue';
    export default {
        components: {
            AddUser,AddPerson,AddJournal,
            // ItemFactory,ItemSale,ItemDesign,
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                tabList:[
                    {name:"工厂"},
                    {name:"设计公司"},
                    {name:"销售公司"},
                ],
                tabIndex:0,
                // comp_type: 企业类型，1具工厂，2设计公司，3销售公司
                queryOpt:{
                    comp_type:1,
                    fd:1,
                    is_self:0,
                    name:null,
                    page:1,
                    pagesize:15,
                },
                is_self:0,
                total:0,
                list:[],
                options:[
                    {id:1,name:"客户名称"},
                    {id:2,name:"标签名称"},
                ],
                searchValue:"",
                userItem:null,
                smsFlag:false,
                mailFlag:false,
                allSelectedId:[],
                user:null,
            }
        },
        watch: {
            queryOpt:{
                handler(){
                    this.debounceRefreshItems();
                },
                deep: true
            }
        },
        created() {
            console.log(this.$store.state.loginUser);
            this.user = this.$store.state.loginUser.user;
            if (this.$route.query.type) {
                this.tabIndex=this.$route.query.type-1;
                this.queryOpt.comp_type = this.$route.query.type;
            } else {
                this.refreshItems();
            }
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
            // console.log(this.queryOpt.comp_type);
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                this.queryOpt = {
                    comp_type:index+1,
                    fd:this.queryOpt.fd,
                    is_self:this.queryOpt.is_self,
                    name:null,
                    page:1,
                    pagesize:15,
                };
                this.$router.push("/client/khgl?type="+this.queryOpt.comp_type);
            },
            // 是否是自己
            changeIsSelf(e) {
                if (this.is_self) {
                    this.queryOpt.is_self = 1;
                } else {
                    this.queryOpt.is_self = 0;
                }
            },
            refreshItems() {
                console.log('refreshItems');
                console.log(this.searchValue);
                if (this.searchValue) {
                    this.search();
                } else {
                    // this.$delete(this.queryOpt,"name");
                    this.queryOpt.name = null;
                    listCompany(this.queryOpt).then(rst => {
                        this.total = rst.total;
                        this.list = rst.list;
                        console.log(this.list);
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
                this.$nextTick(() => {
                    document.querySelector(".el-table__body-wrapper").scrollTop=0;
                });
            },
            search() {
                this.queryOpt.name = this.searchValue;
                console.log(this.queryOpt);
                searchCompany(this.queryOpt).then(rst => {
                    this.total = rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            addJournal(item) {
                this.popTitle = "新增日志";
                this.popVisible = true;
                this.userItem = item;
            },
            FpPerson(item) {
                this.popTitle = "分配人员跟进";
                this.popVisible = true;
                this.ruleForm = {
                    comp_id:item.id,
                    bd_id:item.bd_id,
                };
            },
            showCheck(type) {
                if (type==1) {
                    this.smsFlag = !this.smsFlag;
                    if (!this.smsFlag) {
                        this.$refs.multipleTable.clearSelection();
                        this.allSelectedId = [];
                    }
                } else {
                    this.mailFlag = !this.mailFlag;
                    if (!this.mailFlag) {
                        this.$refs.multipleTable.clearSelection();
                        this.allSelectedId = [];
                    }
                }
            },
            gotoSms() {
                if (this.allSelectedId.length>0) {
                    this.$router.push({
                        path:"/client/smsPush",
                        query:{
                            ids:this.allSelectedId.join('.'),
                        }
                    })
                } else {
                    this.$message.error("请选择要推送的客户");
                }
            },
            gotoMail() {
                if (this.allSelectedId.length>0) {
                    this.$router.push({
                        path:"/client/mailPush",
                        query:{
                            ids:this.allSelectedId.join('.'),
                        }
                    })
                } else {
                    this.$message.error("请选择要推送的客户");
                }
            },
            getRowKeys(row) { 
                return row.id;
            },
            //确定选中
            handleSelectChange(rows) {
                console.log(rows);
                //将选中赋值到回显和传参数组
                this.templateRadio = rows;
                this.allSelectedId = [];
                if (rows) {
                rows.forEach(row => {
                    if (this.smsFlag && row.mobile || this.mailFlag && row.mail) {
                        this.allSelectedId.push(row.id);
                    }
                });
                }
                console.log(this.allSelectedId);
            },
            //点击单选按钮
            // selectSingle(event,item) {
            //     console.log(event);
            //     if (event == true) {
            //         this.allSelectedId.push(item.id);
            //     } else {
            //         for (var i = 0; i < this.allSelectedId.length; i++) {
            //             if (this.allSelectedId[i] === item.id) {
            //                 this.allSelectedId.splice(i, 1);
            //                 break;
            //             }
            //         }
            //     }
            //     console.log(this.allSelectedId);
            // },
            delUser(item) {
                this.$confirm('是否确认删除?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    delCompany({id:item.id}).then(rst => {
                        this.refreshItems();
                        this.$message.success("删除成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            }
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    div>>> .el-dialog {
        width: 524px;
    }
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 80px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:50px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
    .mycell.el-checkbox__input {
        display: none
    }
</style>