<template>
    <div style="max-width: 300px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <div>
                <div style="font-size:14px;font-weight:600;line-height:30px;border-bottom:1px solid #000;">分配跟进人员</div>
                <div style="padding:10px 0;">
                    <el-radio-group v-model="ruleForm.bd_id">
                        <el-radio style="line-height:40px;" v-for="(item,idx) in selectList" :key="idx" :label="item.id">
                            {{item.name}}
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { listManager,assignBd } from '@/service/crm';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入权限分类名称', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    comp_id:"",
                    bd_id:"",
                },
                checkList:"",
                selectList:[],
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            // console.log(this.ruleForm);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                listManager().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                assignBd(this.ruleForm).then(rst => {
                    this.$emit("hide");
                    this.$message.success("分配人员成功");
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    .list>div {
        padding: 5px 0;
    }
    
</style>